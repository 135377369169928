@import '../../style/globalStyles.scss';
@import '../../style/utilities.scss';

.about {
    @include parentpadding;

    &__intro {
        padding-top: 0;
        background-color: black;
        padding-bottom: map-get($sizes, xxxxl);

        @include for-mobile {
        padding-bottom: map-get($sizes, xl);
            &>div {
                gap: map-get($sizes, xl);
            }
        }

        & img {
            width: 500px;

            @include for-mobile {
                width: 100%;
            }
        }
    }

    &>div {
        width: 100%;
    }

    &__experience {
        padding-bottom: 0 !important;

        & h2 {
            text-transform: uppercase;
        }
    }

    &__experience,
    &__education {
        &>div {
            width: 100%;
        }


        &__entry {
            //border-top: 1px solid rgba($primary-color, .1);
            border-top: 1px solid $subtle-color;
            width: 100%;
            flex-wrap: nowrap;
            // align-items: center;


            //padding-left: map-get($sizes, m);
            // border-radius: $default-borderradius;
            // box-shadow: $default-shadow;

            &:first-child {
                //border-top: none;
            }

            &:nth-child(2n) {

                // background-color: rgba($secondary-color, .1);
                & .data {
                    //background-color: rgba($secondary-color, .1);
                }
            }

            @include for-mobile {
                row-gap: 0;

            }

            & .data {
                flex-basis: 350px;
                flex-shrink: 0;
                //text-align: right;
                //background-color: black;
                //color: white;
                //border-right: 2px solid black;
                padding: map-get($sizes, s) 0;
                //border-radius: $default-borderradius;

                @include for-mobile {
                    flex-basis: auto;
                    width: 100%;
                    padding: map-get($sizes, xxs) 0;
                }
            }

            & .desc {
                margin-top: map-get($sizes, l) 0;

                @include for-mobile {
                    flex-basis: auto;
                    width: 100%;
                    padding: map-get($sizes, xxs) 0;
                }
            }
        }

        &__skills {
            background-color: $primary-color;
            // background-color: $secondary-color;
            color: white;

            // border: 2PX solid $secondary-color;
            @include for-mobile {
                width: 100%;
            }
        }

    }

    &__education>div>div {
        flex-basis: calc(50% - map-get($sizes, xl));
    }
}

.data small {}

.skill-bar {
    width: 100%;
    height: map-get($sizes, xxs);
    border: 2px solid $secondary-color;

    &>div {
        background-color: rgba($secondary-color, .6);
        height: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: map-get($sizes, xxs3);
    }
}