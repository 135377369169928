@use "sass:map";
@import '../../style/globalStyles.scss';
@import '../../style/utilities.scss';

.home {
    @include parentpadding;
    width: 100%;

    &__welcome {
        min-height: 100vh;
        background-color: rgba(black, .5);

        h1 {
            text-transform: uppercase;
        }

        &__text {
            height: 100%;
        }
    }

    &__portfolio {
        background-color: white;
        overflow: hidden;

        &__text {
            flex: 1 0 300px;
            height: 35em;
            justify-content: space-around;

            &__button {
                text-transform: uppercase;
            }
        }

        &__image {
            height: 50em;

            @include for-mobile {
                width: 100%;
            }

            flex: 1 1 300px;
            position: relative;
            transition: 1s cubic-bezier(0.65, 0, 0.35, 1);

            &__wrapper {
                position: absolute;
                transition: 1s cubic-bezier(0.65, 0, 0.35, 1);
                top: 0;
                left: 0;
                flex-shrink: 0;

                height: 100%;
                width: 100%;

                display: flex;
                justify-content: center;

                &>img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}


#background-video {
    position: absolute;
    top: 0;
    z-index: -100;
    height: 100vh;
    width: 100%;
    object-fit: cover;
}