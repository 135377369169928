// Breakpoints
$mobile-breakpoint: 599.99px;

@mixin for-desktop {
    @media screen and (min-width: $mobile-breakpoint) {
        @content
    }
}

;

@mixin for-mobile {
    @media screen and (max-width: $mobile-breakpoint) {
        @content
    }
}

;

@mixin hidable {
    &[data-state='hidden'] {
        opacity: 0;
    }

    &[data-state='visible'] {
        opacity: 1;
    }
}

// Fonts:
@import 'https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@500;600;700&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap';

// Sizes:
$sizes: ("xxxxl": 18em,
    "xxxl": 13.5em,
    "xxl": 9em,
    "xl": 6.75em,
    "l2": 5.25em,
    "l": 4.5em,
    "m": 3.5em,
    "s": 3em,
    "xs": 2.25em,
    "xxs": 1.5em,
    "xxs2": 1em,
    "xxs3": 0.75em,
    "xxxs": 0.5em);


$fontsize-h1: 6em;
$fontsize-h2: 4em;
$fontsize-h3: 3em;
$fontsize-h4: 2.75em;
$fontsize-h5: 2em;
$fontsize-p: 2em;
$fontsize-small: 1.75em;




// // Font sizes:
// $fontsize-h1: 4em;
// $fontsize-h2: 3em;
// $fontsize-h3: 2.5em;
// $fontsize-h4: 2em;
// $fontsize-h5: 1.5em;
// $fontsize-p: 1.75em;
// $fontsize-small: 1.25em;

// Font weights:
$fontweight-title: 700;
$fontweight-main: 600;
$fontweight-small: 600;

// Colores
$fontcolor-main: black;
$secondary-color: #62B18B;
$primary-color: #0d2625;
$subtle-color: rgb(166, 166, 166);
//$primary-color: #153b3a;

$accent-color: rgb(247, 255, 90);
// Tipografias
$fontfamily-title: 'Titillium Web';
$fontfamily-main: 'Titillium Web';

$default-gap: 2rem;
$default-borderradius: 0.5em;
$default-shadow: 2px 2px 1px rgba(black, 0.25),

4px 4px 6px rgba(0, 0, 0, 0.08);
$default-transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);

* {
    margin: 0;
    padding: 0;
    //width: 100%;
    box-sizing: border-box;
}


body {
    font-family: $fontfamily-main, Arial;
    font-weight: $fontweight-main;
    color: $fontcolor-main;

    &>#root {
        display: flex;
        justify-content: center;
    }

    font-size: 10px;

    @include for-mobile {
        font-size: 10px;
    }
}

h1,
h2,
h3,
h4 {
    font-weight: $fontweight-title;
    font-family: $fontfamily-title;
    line-height: 100%;
}

h1 {
    font-size: $fontsize-h1;

}

h2 {
    font-size: $fontsize-h2;
}

h3 {
    font-size: $fontsize-h3;
}

h4 {
    font-size: $fontsize-h4;
}

h5 {
    font-size: $fontsize-h5;
}

p {
    font-size: $fontsize-p;
    font-family: $fontfamily-main;
    font-weight: $fontweight-main;
    line-height: 133%;
}

small {
    font-size: $fontsize-small;
    font-family: $fontfamily-main;
    font-weight: $fontweight-small;
}

img {
    object-fit: cover;
}

a {
    text-decoration: none;
    color: inherit;
}