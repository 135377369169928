@mixin flex($direction: row) {
    display: flex;
    flex-direction: $direction;

    &.center {
        justify-content: center;
        align-items: center;
    }

    &.center-x {
        @if $direction==row {
            justify-content: center;
        }

        @else {
            align-items: center;
        }
    }

    &.center-y {
        @if $direction==row {
            align-items: center;
        }

        @else {
            justify-content: center;
        }
    }

    &.spacebetween-x {
        @if $direction==row {
            justify-content: space-between;
        }

        @else {
            align-items: space-between;
        }
    }

    &.spacearound-x {
        @if $direction==row {
            justify-content: space-around;
        }

        @else {
            align-items: space-around;
        }
    }

    &.spacearound-y {
        @if $direction==row {
            align-items: space-around;
        }

        @else {
            justify-content: space-around;
        }
    }

    &.right{
        @if $direction==row {
            justify-content: flex-end;
        }

        @else {
            align-items: flex-end;
          
        }
    }
}

.flex-row {
    @include flex(row);
}

.flex-column {
    @include flex(column);
}

.responsive-row {
    @include for-desktop {
        @include flex(row);
        flex-wrap: wrap;
    }

    @include for-mobile {
        @include flex(column);
        align-items: center;
    }

    &.nw {
        flex-wrap: nowrap;
    }
}


h1,
h2,
h3,
h4,
h5,
p,
small {
    &.center {
        text-align: center;
    }
}

svg {
    width: auto;
}

@mixin button {
    background-color: $primary-color;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $fontsize-h5;
    font-family: $fontfamily-title;
    padding: 0.75em;
    border-radius: $default-borderradius;
    text-align: center;
    cursor: pointer;
    gap: 0.3em;
    width: auto;
    transition: 0.2s;
    background-color: black;

    &:hover {
        background-color: $primary-color;
    }

}

.button {
    @include button();

    &-green {
        @include button();
        background-color: rgba($secondary-color, .8);

        &:hover {
            background-color: rgba($secondary-color, 1);
        }

    }

    &-white {
        color: black !important;
        @include button();
        background-color: rgba(white, .8);

        &:hover {
            background-color: rgba(white, 1);
        }

    }

    &-black {
        color: white !important;
        @include button();
        background-color: rgba(black, 1);

        &:hover {
            background-color: rgba(black, .8);
        }

    }
}

.roundborder {
    border-radius: $default-borderradius;
}

.section-group {
    *>div {
        display: flex;
        flex-direction: column;
    }
}