@import '../../style/globalStyles.scss';
@import '../../style/utilities.scss';

.portfolio {
    width: 100vw;
    @include parentpadding;
    &__welcome {
        padding-top: 0;
        padding-bottom: map-get($sizes, m);
    }

    &>div {
        width: 100%;
    }

    & iframe {
        width: 100%;
        aspect-ratio: 16/9;
    }

    &__gallery {
        position: relative;
        transition: $default-transition;
        background-color: white;
        padding-bottom: map-get($sizes, l);

        &__transition {
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: white;
            z-index: 5000;
            transition: $default-transition;
            pointer-events: none;
        }
    }
}