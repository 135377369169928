@import '../../style/globalStyles.scss';
@import '../../style/utilities.scss';

.gallery {
    width: 100%;
    transition: $default-transition;
    padding-bottom: map-get($sizes, m);

    &__wrapper {
        display: grid;

        & .img-parent {
            display: flex;
            position: relative;
            cursor: pointer;

            & img {
                max-width: 100%;
                width: 100%;
            }

            .img-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                background-color: rgba(black, 0.8);
                opacity: 0;
                transition: $default-transition;
            }

            &:hover .img-overlay {
                opacity: 1;
            }

            & .button-white {
                font-size: map-get($sizes, xxs);
                // text-transform: uppercase;
            }
        }
    }

}

.fullscreen{
    background-color: rgba(black, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 10vh;
    transition: $default-transition;
    cursor: pointer;

    &[data-state="enabled"]{
        opacity: 1;
    }

    &[data-state="disabled"]{
        opacity: 0;
        pointer-events: none;
    }

    img{
        max-width: calc(100vw - 10vh);
        max-height: 90vh;
        object-fit: contain;
    }
}