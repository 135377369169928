@import '../../../style/globalStyles.scss';

.work-details {
    width: 100%;

    &__text {
        flex: 1 0 300px;
        @include for-mobile {
            flex: auto;
        }
    }

    &__carousel {
        &-wrapper {
            flex: 1 1 700px !important;
            @include for-mobile {
                flex: auto !important;
            }
        }

        width: 100%;
        height: 400px !important;

        &>div {
            height: 100%;

        }

        &__slide {
            background-color: white;
            height: 100%;

            & img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

        }
    }
}

[role="tablist"] {
    gap: 8px;
}

.slider-control-centerright,
.slider-control-centerleft {
    & button {
        border: none;
        opacity: 1;
        position: absolute;
        height: 32px;
        width: 32px;
        border-radius: 100px;
        background-color: rgba(black, 0.7);
        cursor: pointer;

        & svg {
            fill: white;
            height: 16px;
            transform: translateY(1px);
        }

        &[disabled] {
            opacity: 0.1;
        }
    }
}


[aria-label="next"] {
    opacity: 0 !important;
}