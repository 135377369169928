@import '../../../style/globalStyles.scss';
@import '../../../style/utilities.scss';

.gallery {
    width: 100%;
    transition: $default-transition;
    padding-bottom: map-get($sizes, m);

    &__wrapper {
        display: grid;

        & .img-parent {
            display: flex;
            flex-direction: column;
            background-color: white;
            position: relative;
            cursor: pointer;
            box-shadow: $default-shadow;

            & img {
                max-width: 100%;
                width: 100%;
            }

            .img-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                background-color: rgba(black, 0.8);
                opacity: 0;
                transition: $default-transition;
            }

            &:hover .img-overlay {
                opacity: 1;
            }

            & .button-white {
                font-size: map-get($sizes, xxs);
                // text-transform: uppercase;
            }
        }
    }

}

.videos__card {
    border-radius: $default-borderradius;
    flex: 1 1 360px;
    background-color: white;
    display: flex;
    flex-direction: column;

    
    &[data-state="hidden"]{
        display: none;
    }

    & img {
        border-top-left-radius: $default-borderradius;
        border-top-right-radius: $default-borderradius;

        width: 100%;
        height: 350px;
        object-fit: cover;
    }

    &__text {
        flex-grow: 1;
        & h4{
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
                    line-clamp: 1; 
            -webkit-box-orient: vertical;
        }

        &__desc {
            // max-height: 200px;
            // text-overflow: ellipsis;

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4; /* number of lines to show */
                    line-clamp: 4; 
            -webkit-box-orient: vertical;
            // overflow: hidden;
        }

        & .btn-wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: flex-end;
            margin-top: 12px;
        }

        & .btn {

        }

    }
}