@import '../../../style/globalStyles.scss';

.goback {
    width: 100%;

    &__link {
        background-color: transparent;
        color: black;
        margin-bottom: map-get($sizes, xxxs);
        padding-left: 0;

        &:hover {
            color: #424242;
            background-color: transparent;

        }
    }
}