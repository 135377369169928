@import '../../../style/utilities.scss';
@import '../../../style/globalStyles.scss';
.video-page-parent{
    width: 100vw;
    @include parentpadding;

    & .video {


        &-wrapper {
            width: 100%;
            background-color: rgba($secondary-color, 0.1);
            background-attachment: initial !important;
            padding-bottom: 60px;

            & h2{
                padding-bottom: 30px;
            }
    
            & .goback__link{
                padding: 0;
            }
    
            & .iframe-wrapper {
                width: 100%;

                row-gap: 42px !important;

                @include for-mobile {
                    flex-direction: column-reverse;
                }
            }
    
            & iframe {
                width: 100%;
                aspect-ratio: 16/9;
                flex: 1 1 700px !important;
    
                @include for-mobile {
                    flex: auto !important;
                }
            }
        }
    
    
    }
    
    
    
    & .work-details {
        width: 100%;
    
        &__text {
            flex: 1 0 300px;
    
            @include for-mobile {
                flex: auto;
            }
        }
    
    }
}

