@import '../../style/utilities.scss';

.videos-wrapper {
    @include parentpadding;
    background-color: white !important;
}

.welcome {
    @include parentpadding;

    & iframe {
        width: 100%;
        aspect-ratio: 16/9;
    }

    padding-bottom: map-get($sizes, l);
    & h2{
        text-align: left;
        width: 100%;
    }
}

.featured-section{
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.featured-video-block{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include for-mobile {
        grid-template-columns: 1fr;
    }
}

.videos {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    & a {
        display: flex;
    }

    &__contact {
        @include parentpadding;

    }

    &-wrapper {
        background-color: rgba($secondary-color, 0.1);
        background-attachment: initial !important;

        & h2 {
            padding: 30px;
        }

        & img {
            aspect-ratio: 16/9;
        }
    }

    &__card {
        border-radius: $default-borderradius;
        flex: 1 1 360px;
        background-color: white;
        display: flex;
        flex-direction: column;

        
        &[data-state="hidden"]{
            display: none;
        }

        & img {
            border-top-left-radius: $default-borderradius;
            border-top-right-radius: $default-borderradius;

            width: 100%;
            height: 350px;
            object-fit: cover;
        }

        &__text {
            flex-grow: 1;
            & h4{
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                        line-clamp: 1; 
                -webkit-box-orient: vertical;
            }

            &__desc {
                // max-height: 200px;
                // text-overflow: ellipsis;

                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4; /* number of lines to show */
                        line-clamp: 4; 
                -webkit-box-orient: vertical;
                // overflow: hidden;
            }

            & .btn-wrapper {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: flex-end;
                margin-top: 12px;
            }

            & .btn {

            }

        }
    }
}