@import '../../style/globalStyles.scss';

@mixin navbar-button-position {
    position: fixed;
    top: map-get($sizes, s);
    right: map-get($sizes, s);
}

.navbar {
    @include hidable;
    z-index: 1000;
    height: 100svh;
    width: 100%;
    position: fixed;
    transition: 0.3s;
    user-select: none;

    &[data-state='hidden'] {
        pointer-events: none;
    }

    & .option {
        width: max-content;
        cursor: pointer;

        @include for-mobile {
            font-size: 8px;
        }

        & svg {
            height: 90%;
        }

        & img {
            height: 2.5em;
            width: 3em;
            transform: translateY(0.7em);
        }
    }

    // border-right: 4px solid rgb(15, 15, 15);

    &__child {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.95);
        padding-top: 0;

        &__header {
            justify-items: flex-start;
            //position: absolute;
        }

        &>.responsive-row {
            height: 100%;
            width: 100%;
        }


        & *[data-state='inactive'] {
            opacity: 0.5;

            &:hover {
                opacity: 0.75;
            }
        }


    }
}

.toggle-navbar {
    z-index: 2000;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0.4rem;
    color: black;
    stroke-width: 0.06rem;
    width: map-get($sizes, xl);
    height: map-get($sizes, xl);
    @include navbar-button-position;

    cursor: pointer;

    & .open {
        @include navbar-button-position;
        width: 5em;
        height: 5em;
        margin-top: 8px;
        margin-right: 8px;
        transition: $default-transition;
    }

    & .close {
        @include navbar-button-position;
        width: 5em;
        height: 5em;
        margin-top: 8px;
        margin-right: 8px;
        transition: $default-transition;
    }


    &[data-state='hidden'] {
        & .open {
            opacity: 0;
            right: 20px;
        }

        & .close {
            opacity: 1;
        }
    }

    &[data-state='visible'] {
        & .open {
            opacity: 1;
        }

        & .close {
            opacity: 0;
            right: 20px;
        }
    }

    &:hover {
        background-color: black;
        color: white;
    }

}