@import '../../../style/globalStyles.scss';

.selector {
    &__option {
        transition: $default-transition;
        cursor: pointer;
        //text-transform: uppercase;
        margin-top: map-get($sizes, xxs);
        margin-bottom: map-get($sizes, xxs);


        &[data-state='active'] {
            border-bottom: 2px solid black;
        }

        &[data-state='inactive'] {
            color: rgb(138, 138, 138);
            border-bottom: 3px solid rgba(0, 0, 0, 0);
        }
    }
}