@import '../../style/globalStyles.scss';
@import '../../style/utilities.scss';

.contact {
    color: white;

    & div {
        @include for-mobile {
            width: 100%;

        }
    }
    &__slogan {
        text-align: center;

        @include for-mobile {
            padding: map-get($sizes, xxs) !important;
        }
    }

    &__media {
        @include for-mobile {
            gap: map-get($sizes, l) !important;
        }

        &__rrss__parent{
            @include for-mobile {
                gap: map-get($sizes, l) !important;
            }
        }
        &__form {
            flex-grow: 1;

            & * {
                flex-grow: 1;
                height: auto;

            }

        }
    }
}

.input-group {
    display: flex;
    flex-direction: column;
    gap: map-get($sizes, xxxs);

    @include for-mobile {
        width: 100%;
    }

    & input,
    textarea {
        height: map-get($sizes, s);
        border-radius: $default-borderradius;
        font-size: map-get($sizes, xxs);
        padding: map-get($sizes, xxxs);
        font-weight: 500;
        font-family: $fontfamily-main;
        vertical-align: text-bottom;
    }

    &.message textarea {
        height: map-get($sizes, xxl);
    }
}